html {
  font-size:14px;
  @apply text-gray-900 leading-6;
 }
.accent-text {
  color: var(--color-accent);
}
.accent-color {
  background-color: var(--color-accent);
}
.primary-text {
  @apply font-semibold;
  color: var(--color-primary);
}
a.text-link {
  @apply underline;
}
.primary-color,
.primary-colour {
  color: var(--color-primary) !important;
}

input.focus-border-accent {
  border-color: #fff;
  border-style: solid;
  border-width: 1px;
}

input.focus-border-accent:focus {
  border-color: var(--color-accent) !important;
}

.user-task-comment-owner {
  color: white;
  background-color: var(--color-accent);
}

.user-task-comment-non-owner {
  color: slategray;
  background-color: color-mix(in srgb, slategray 20%, white)
}

[contenteditable=true]:not([placeholder=""]):empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
  cursor: text;
}