button.btn-primary,
a.btn-primary {
  @apply text-white text-center border-0 drop-shadow-sm py-1 px-6 m-0 rounded-md font-normal;
  background-color: var(--color-primary);
}

.text-primary {
  color: var(--color-primary);
}
.text-primary-faded {
  color: var(--color-primary);
  opacity: 0.2;
}
.btn-primary:disabled {
  @apply !bg-gray-200 text-gray-400;
}

.btn-primary-alternative {
  @apply text-center border-0 drop-shadow-sm py-1 px-6 m-0 rounded-md font-normal;
  color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
}

.btn-primary:hover {
  filter: brightness(120%);
}

button.btn-primary:disabled {
  @apply bg-gray-200 text-gray-400;
}
button:disabled:hover {
  filter: brightness(100%);
}

.btn-secondary {
  @apply bg-transparent text-gray-700 font-normal text-center border border-gray-700 drop-shadow-sm py-1 px-6 m-0 rounded-md;
}
.btn-secondary:hover {
  border: 1px solid var(--color-primary);
}

.journey-btn-primary {
  @apply bg-white text-black mix-blend-screen decoration-transparent text-center border-0 drop-shadow-sm py-1 px-6 m-1 rounded-md;
}

.journey-btn-secondary {
  @apply bg-white/20 text-center text-white py-1 px-6 rounded-md m-1;
}

.journey-btn-primary:hover {
  @apply bg-white/90;
}
.journey-btn-primary[disabled="disabled"],
.journey-btn-primary:disabled,
button[disabled="disabled"] .journey-btn-primary,
button:disabled .journey-btn-primary {
  @apply bg-white/60 cursor-not-allowed;
}

button[disabled="disabled"].keyboard-pager .journey-btn-primary,
button:disabled.keyboard-pager .journey-btn-primary,
button[disabled="disabled"] .journey-btn-label,
button:disabled .journey-btn-label {
  @apply hidden;
}

.journey-btn-secondary:hover {
  @apply bg-white/90 text-black mix-blend-screen;
}

button.btn-primary.btn-small,
button.btn-secondary.btn-small,
a.btn-primary.btn-small,
a.btn-secondary.btn-small {
  @apply px-3 text-sm;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.bg-primary-faded {
  background-color: var(--color-primary);
  opacity: 0.2;
}

.border-primary {
  border: 1px solid var(--color-primary);
}

.show-more-btn {
  color: var(--color-primary);
}

.show-more-btn:hover {
  filter: brightness(120%);
}
